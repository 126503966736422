.office-container {
    border-top: 0.5rem solid #00A1DE;
}

.hours-header {
    border-top: 0.5rem solid #00933C;
    padding-top: 0.25rem;
}

.contact-header {
    border-top: 0.5rem solid #FCCC0A;
    padding-top: 0.25rem;
}

.connect-header {
    border-top: 0.5rem solid #996633;
    padding-top: 0.25rem;
}

.office-item {
    border: 1px solid rgb(167, 169, 172, 0.5);
}

.our-office-img {
    background-image: url('../assets/our_office.jpg');
    background-size: cover;
    background-position: center;
    min-height: 350px;
}