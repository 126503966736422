.testimonial-container {
    border-top: 0.5rem solid #fcd20a;
    background-color: #4b83d6;
    color: #ffffff;
}

.blockquote {
    border-top: 0.25rem solid #0039A6;
    padding-top: 0.25rem;
}

.blockquote-footer {
    color: #ffffff;
}