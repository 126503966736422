.services-container {
    border-top: 0.5rem solid #B933AD;
}

/* size md */
@media (min-width: 768px) {
    .services-col {
        border-right: 0.5rem solid #FF6319;
    }
}

.accordion-item {
    border-color: #A7A9AC;
}

.service-item {
    border: 1px solid rgb(167, 169, 172, 0.5);
}

.accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #4b83d6;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23YOUR_HEX_CODE'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.our-services-img {
    background-image: url('../assets/our_services.jpg');
    background-size: cover;
    background-position: center;
    min-height: 350px;
}