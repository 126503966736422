.practice-container {
    border-top: 0.5rem solid #0039A6;
}

/* size md */
@media (min-width: 768px) {
    .practice-col {
        border-left: 0.5rem solid #6CBE45;
    }
}

.our-practice-img {
    background-image: url('../assets/our_practice.jpg');
    background-size: cover;
    background-position: center;
    min-height: 350px;
}