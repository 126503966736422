html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', sans-serif;
}

a {
  color: #0039A6;
}

.App {
  text-align: center;
}

.v-center-col {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}