.team-container {
    border-top: 0.5rem solid #EE352E;
}

.card {
    border-color: #A7A9AC;
}

.our-team-img {
    background-image: url('../assets/our_team.jpg');
    background-size: cover;
    background-position: center;
    min-height: 350px;
}