.offcanvas {
    color: #ffffff;
    background-color: #000000;
}

.offcanvas a {
    color: #ffffff;
}

.navbar-dark .navbar-toggler {
    background: #000000;
}

.offcanvas a:not(:last-child) {
    border-bottom: 1px solid #ffffff;
}