.subway-col {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mural-col {
    height: 100vh;
    padding: 0;
    background-image: url('../assets/mural.jpg');
    background-size: cover;
}

.cta {
    margin-bottom: 10rem;
}